import React, { useRef, useState } from "react";
import "../styles/main.scss";
import EAM360LOGO from "../images/IBM_Event/EAM-LOGO.svg";
import SedinAndIBMLOGO from "../images/IBM_Event/Sedin-ibm-logo.svg";
import SedinAndIBMLOGOMobile from "../images/IBM_Event/small-sedin-ibm.svg";
import CalenderIcon from "../images/IBM_Event/calender.svg";
import TimeIcon from "../images/IBM_Event/time.svg";
import LocationIcon from "../images/IBM_Event/location.svg";
import CallIcon from "../images/maximo/call-icon.svg";
import { StaticImage } from "gatsby-plugin-image";
import { navigate } from "gatsby";
import axios from "axios";
import { Helmet } from "react-helmet";

const LandingPage = () => {
    const formAPIURL = `${process.env.GATSBY_BLOG_FORM_URL}`;

    const [stepForm, setFormState] = useState({
        fullName: "",
        jobTitle: "",
        companyName: "",
        email: "",
        phone: "",
        terms: false,
    });
    const [isLoading, setLoading] = useState(false);
    const formEl = useRef(null);
    const [formSubmitError, setSubmitError] = useState("");
    const [errors, setErrors] = useState({});
    const validateForm = () => {
        let formErrors = {};

        // Email validation
        if (!stepForm.email) {
            formErrors.email = "Email is required.";
        } else if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(stepForm.email)) {
            formErrors.email = "Please enter a valid email address.";
        }

        // Phone number validation
        if (!stepForm.phone) {
            formErrors.phone = "Phone number is required.";
        } else if (!/^\d{10}$/.test(stepForm.phone)) {
            formErrors.phone = "Please enter a valid 10-digit phone number.";
        }

        // Terms validation
        if (!stepForm.terms) {
            formErrors.terms = "You must agree to the terms.";
        }

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };
    function handleFormSubmit(e) {
        e.preventDefault();
        const isValid = validateForm();
        if (isValid) {
            setLoading(true);
            const formdata = stepForm;
            axios
                .post(formAPIURL, formdata, {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                })
                .then((resp) => {
                    setFormState({
                        fullName: "",
                        jobTitle: "",
                        companyName: "",
                        email: "",
                        phone: "",
                        terms: false,
                    });
                    setLoading(false);
                    navigate("/contact/thank-you/");
                })
                .catch((err) => {
                    setLoading(false);
                    setSubmitError("Oops, Something went wrong. Please try again");
                });
        }
        console.log("formdata ", stepForm);
    }
    function inputsHandler(e) {
        const { name, value, type, checked } = e.target;

        setFormState((prevState) => ({
            ...prevState,
            [name]: type === "checkbox" ? checked : value,
        }));
    }
    // scroll to form section
    const scrollToForm = () => {
        const formSection = document.getElementById("ibm-event-form");
        if (formSection) {
            formSection.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <section className="IBM-header">
                <div className="container">
                    <div className="site-logo">
                        <img
                            // src={EAM360LOGO}
                            src={SedinAndIBMLOGO}
                            alt="sedin-logo"
                            loading="lazy"
                            className="logo logo-mobile"
                        />
                        <img
                            // src={EAM360LOGO}
                            src={SedinAndIBMLOGOMobile}
                            alt="sedin-logo"
                            loading="lazy"
                            className="logo logo-desktop"
                        />
                        {/* <div className="cta-btn">
                            <button className="contact-btn">Contact Us</button>
                        </div> */}
                        <div className="flex flex-ai-c maximo-call">
                            <img src={CallIcon} alt="call-icon" className="call-icon-logo" />
                            <a className="text-p2 text-clr-white" href="tel: +91 733 881 5294">
                                Call Us: +91 733 881 5294
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            {/* hero section */}
            <section className="IBM-Hero-Sec">
                <div className="container">
                    <div className="IBM-Hero-Sec__text">
                        <h1 className="IBM-Hero-Sec__text-h1">
                            Operate Critical Assets with IBM Maximo Application Suite
                        </h1>
                        <div className="IBM-Hero-Sec__text-grid">
                            <div className="">
                                <div className="IBM-Hero-Sec__text-grid-location">
                                    <img src={CalenderIcon} alt="icon" />
                                    <p className="text-p1 IBM-Hero-Sec__text-p1">
                                        December 12, 2024
                                    </p>
                                </div>
                                <div className="IBM-Hero-Sec__text-grid-location">
                                    <img src={TimeIcon} alt="icon" />
                                    <p className="text-p1 IBM-Hero-Sec__text-p1">
                                        6:30 PM onwards followed by dinner
                                    </p>
                                </div>
                                <div className="IBM-Hero-Sec__text-grid-location">
                                    <img src={LocationIcon} alt="icon" />
                                    <p className="text-p1 IBM-Hero-Sec__text-p1">
                                        Novotel Chennai Chamiers Road, Nandanam, Chennai - 600035
                                    </p>
                                </div>
                            </div>
                            <div className="">
                                <button
                                    className="register-btn"
                                    onClick={scrollToForm}
                                    type="submit"
                                >
                                    Register Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* middle sec- one  */}
            <section className="IBM-middle-sec-one">
                <div className="container container--first-v4">
                    <div className="IBM-middle-sec-one__text">
                        <h1 className="text-h1 ibm-h1 h1-heading">
                            Asset Strategy and Maintenance Intelligence – Leadership Conference
                        </h1>
                        <p className="text-p1 p1-para">
                            Sedin Technologies, in collaboration with IBM and Redington, invites you
                            to this exclusive event.
                        </p>
                        <h1 className="text-h1 ibm-h1 h1-heading left-h1">
                            Discover how IBM Maximo Application Suite (MAS) can transform the way
                            you maintain & manage assets.
                        </h1>
                        <h3 className="text-h3 h3-heading">Why attend?</h3>
                    </div>
                    <div className="IBM-middle-sec-one__grid">
                        <div className="IBM-middle-sec-one__grid-item">
                            <p className="text-p1 grid-p1">
                                Elevate overall equipment effectiveness by effectively managing
                                resources and ensuring timely execution of work orders.
                            </p>
                        </div>
                        <div className="IBM-middle-sec-one__grid-item">
                            <p className="text-p1 grid-p1">
                                Use AI to extract data from your documents, answer questions, and
                                access historical data.
                            </p>
                        </div>
                        <div className="IBM-middle-sec-one__grid-item">
                            <p className="text-p1 grid-p1">
                                Network with Industry leaders and learn strategies from leading
                                organizations to maintain & manage critical assets.
                            </p>
                        </div>
                    </div>
                    <div className="IBM-middle-sec-one__grid-venue">
                        <div className="IBM-middle-sec-one__grid-venue-img">
                            <StaticImage
                                src="../images/IBM_Event/venue-img.png"
                                alt="Venue-img"
                                loading="lazy"
                            />
                        </div>
                        <div className="IBM-middle-sec-one__grid-venue-address">
                            <div className="IBM-Hero-Sec__text-grid-location">
                                <img src={CalenderIcon} alt="icon" />
                                <p className="text-p1 IBM-Hero-Sec__text-p1">December 12, 2024</p>
                            </div>
                            <div className="IBM-Hero-Sec__text-grid-location">
                                <img src={TimeIcon} alt="icon" />
                                <p className="text-p1 IBM-Hero-Sec__text-p1">
                                    6:30 PM onwards followed by dinner
                                </p>
                            </div>
                            <div className="IBM-Hero-Sec__text-grid-location">
                                <img src={LocationIcon} alt="icon" />
                                <p className="text-p1 IBM-Hero-Sec__text-p1">
                                    Novotel, Chennai Charmiers
                                </p>
                            </div>
                            <button className="register-btn" onClick={scrollToForm}>
                                Register Now
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            {/* our team sec  */}
            {/* <section className="our-speakers">
                <div className="container container--first-v4">
                    <div className="our-speakers__text">
                        <h1 className="text-h1-ibm h1-heading">Meet the key speakers</h1>
                    </div>
                    <div className="our-speakers__grid">
                        <div className="our-speakers__grid-item">
                            <StaticImage
                                src="../images/IBM_Event/client1.png"
                                alt="Krishnakumar-img"
                                loading="lazy"
                                className="gap-05x"
                            />
                            <h3 className="text-h3 speaker-name">Krishnakumar</h3>
                            <p className="text-p1 speaker-role">Job Role</p>
                        </div>
                        <div className="our-speakers__grid-item">
                            <StaticImage
                                src="../images/IBM_Event/client1.png"
                                alt="Poornima-img"
                                loading="lazy"
                                className="gap-05x"
                            />
                            <h3 className="text-h3 speaker-name">Poornima</h3>
                            <p className="text-p1 speaker-role">Manager- Maximo Consultant</p>
                        </div>
                        <div className="our-speakers__grid-item">
                            <StaticImage
                                src="../images/IBM_Event/client1.png"
                                alt="Rajesh-img"
                                loading="lazy"
                                className="gap-05x"
                            />
                            <h3 className="text-h3 speaker-name">Rajesh</h3>
                            <p className="text-p1 speaker-role">Lead Product Consultant</p>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* form section  */}
            <section className="IBM-form-sec">
                <div className="container">
                    <div className="IBM-form-sec__text">
                        <h1 className="text-h1 ibm-h1 h1-heading">Reserve Your Spot Now!</h1>
                    </div>
                    <div className="IBM-form-sec__form-grid">
                        <form
                            id="ibm-event-form"
                            className="reserve-form"
                            // onSubmit={handleFormSubmit}
                            onSubmit={(e) => handleFormSubmit(e)}
                            ref={formEl}
                            noValidate
                            autoComplete="off"
                        >
                            <div className="form-field">
                                <input
                                    type="text"
                                    id="fullName"
                                    name="fullName"
                                    placeholder="Full name"
                                    value={stepForm.fullName}
                                    onChange={inputsHandler}
                                    required
                                />
                                {/* <label htmlFor="fullName">Full name</label> */}
                            </div>
                            <div className="form-field">
                                <input
                                    type="text"
                                    id="jobTitle"
                                    name="jobTitle"
                                    placeholder="Job Title"
                                    value={stepForm.jobTitle}
                                    onChange={inputsHandler}
                                    required
                                />
                                {/* <label htmlFor="jobTitle">Job Title</label> */}
                            </div>
                            <div className="form-field">
                                <input
                                    type="text"
                                    id="companyName"
                                    name="companyName"
                                    placeholder="Company Name"
                                    value={stepForm.companyName}
                                    onChange={inputsHandler}
                                    required
                                />
                                {/* <label htmlFor="companyName">Company Name</label> */}
                            </div>
                            <div className="form-field">
                                <input
                                    type="email"
                                    id="emailAddress"
                                    name="email"
                                    placeholder="Email Address"
                                    value={stepForm.email}
                                    onChange={inputsHandler}
                                    required
                                />
                                {errors.email && <p className="error-text">{errors.email}</p>}
                                {/* <label htmlFor="emailAddress">Email Address</label> */}
                            </div>
                            <div className="form-field">
                                <input
                                    type="tel"
                                    id="phoneNumber"
                                    name="phone"
                                    placeholder="Phone Number"
                                    value={stepForm.phone}
                                    onChange={inputsHandler}
                                    required
                                />
                                {errors.phone && <p className="error-text">{errors.phone}</p>}
                                {/* <label htmlFor="phoneNumber">Phone Number</label> */}
                            </div>
                            <div className="form-field terms-field">
                                <input
                                    type="checkbox"
                                    id="terms"
                                    name="terms"
                                    checked={stepForm.terms}
                                    onChange={inputsHandler}
                                />
                                <label
                                    htmlFor="terms"
                                    className="terms-conditions-text text-p4 text-clr-secondary text-fw-regular"
                                >
                                    "I agree to share my information with Sedin and understand it
                                    will be used as described in their Privacy Policy."
                                </label>
                                {errors.terms && <p className="error-text">{errors.terms}</p>}
                            </div>
                            <button
                                type="submit"
                                className="form-submit-btn"
                                // disabled={Object.keys(errors).length > 0 || isLoading}
                            >
                                {/* {isLoading ? "Submitting" : "Register Now"} */}
                                Register Now
                            </button>
                        </form>
                    </div>
                </div>
            </section>

            {/* footer sec  */}
            <section className="ibm-footer-conference">
                <div className="container">
                    <div className="ibm-footer-conference__content">
                        <img
                            src={EAM360LOGO}
                            alt="sedin-logo"
                            loading="lazy"
                            className="sedin-logo-text gap-05x"
                        />
                        <p className="footer-para gap-4x">
                            Reach out to EAM360 at{" "}
                            <a href="mailto:sales@eam360.com">sales@eam360.com</a> If you have any
                            questions.
                        </p>
                        <p className="footer-rights">
                            © 2024 Sedin Technologies Private Limited | All Rights Reserved
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
};

export default LandingPage;
